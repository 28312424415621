const TimeFormatter = ({ createdAt }) => {
  const formatDateTime = (timestamp) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(timestamp).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  return <span>{formatDateTime(createdAt)}</span>;
};

export default TimeFormatter;
