import React from "react";

const Privacy = () => {
  const content = [
    {
      title: " Information We Collect",
      desc: ` We may collect personal information from you when you
      interact with our website, make a purchase, register for
      an account, sign up for newsletters, participate in
      surveys or promotions, or contact customer support. This
      information may include your name, email address, shipping
      address, billing information, phone number, and other
      details relevant to your transactions.`,
    },
    {
      title: "How We Use Your Information",
      desc: `
      We use the information we collect to process your orders, communicate with you about your purchases, provide customer support, improve our products and services, personalize your shopping experience, and send you promotional offers and updates if you have opted in to receive them. We may also use aggregated and anonymized data for analytics and marketing purposes.
      `,
    },
    {
      title: " Sharing Your Information",
      desc: ` We may share your personal information with third-party service providers who assist us in operating our website, processing payments, delivering orders, conducting marketing campaigns, and providing other services. These service providers are contractually obligated to use your information only for the purposes of providing the services we have requested.`,
    },
    {
      title: "Data Security",
      desc: `
      We implement a variety of security measures to protect your personal information and maintain its confidentiality. These measures include encryption, access controls, and regular security audits. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
      `,
    },
    {
      title: "Your Choices",
      desc: `
      You have the right to access, update, or delete your personal information. You may also opt out of receiving promotional communications from us at any time by following the instructions provided in those communications or by contacting us directly. Please note that even if you opt out of marketing communications, we may still send you transactional or administrative messages related to your account or purchases.
      `,
    },
    {
      title: "Changes to This Policy",
      desc: `
      We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or by sending you a notification. Your continued use of our website after the effective date of the updated policy constitutes your acceptance of the changes.
      `,
    },
    {
      title: "Contact Us",
      desc: `
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at (link Contact Us Page here)
NB: This Privacy Policy was last updated in March, 2024.

      `,
    },
  ];
  return (
    <>
      <section className="about__section section--padding mb-95">
        <div className="container">
          <div className="blog__details--wrapper">
            <div className="entry__blog">
              <div
                className="blog__post--header mb-30"
                style={{ marginBottom: "30px" }}
              >
                <h2
                  className="post__header--title mb-15"
                  style={{
                    textAlign: "center",
                    color: "var(--secondary-color)",
                  }}
                >
                  OUR PRIVACY POLICY
                </h2>
              </div>

              <div className="blog__details--content">
                <p
                  className="blog__details--content__subtitle mb-25"
                  style={{
                    fontSize: "14px",
                    color: "#666",
                    marginBottom: "25px",
                  }}
                >
                  At Devanti Couture, we take your privacy and security
                  seriously. This Privacy Policy outlines how we collect, use,
                  disclose, and protect your personal information when you use
                  our website and services. By accessing or using our website,
                  you agree to the terms of this Privacy Policy.
                </p>
                <ul className="blog__details--content__desc mb-20">
                  {content.map((item) => (
                    <li key={item.title}>
                      <h4
                        className="post__header--title mb-10"
                        style={{ fontSize: "20px", fontWeight: "600" }}
                      >
                        {item.title}
                      </h4>
                      <p
                        className="blog__details--content__subtitle mb-25"
                        style={{
                          marginBottom: "25px",
                          fontSize: "14px",
                          color: "#666",
                        }}
                      >
                        {item.desc}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
