import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import logo from "../assets/img/logo/nav-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkoutSchema } from "../schemas/validation";
import useAuthStore from "../store/store";
import { toast } from "react-toastify";
import useUpdateCartStore from "../store/cart";
import { jwtDecode } from "jwt-decode";
import { Axios } from "../config";
import requests from "../lib/requests";
import { PaystackButton } from "react-paystack";

const Checkout = () => {
  const { newToken, token } = useAuthStore();
  const userData = newToken ? jwtDecode(newToken) : null;
  const navigate = useNavigate();
  const location = useLocation();
  const { products } = location.state || {};
  const { resetCart } = useUpdateCartStore();
  const [loading, setLoading] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [shippingInfo, setShippingInfo] = useState("Customer Information");
  const [shippingData, setShippingData] = useState([]);
  const [loadingShippingOption, setLoadingShippingOption] = useState(false);

  const fetchShipping = async () => {
    setLoadingShippingOption(true);
    try {
      const response = await Axios.get(requests.shippingmethod, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShippingData(response.data);
      setLoadingShippingOption(false);
      return response.data;
    } catch (error) {
      toast.error("Failed to Shipping Method", {
        toastId: 1,
        duration: "300",
      });
      setLoadingShippingOption(false);
    } finally {
      setLoadingShippingOption(false);
    }
  };

  useEffect(() => {
    fetchShipping();
  }, []);

  const shippingTabs = [
    { tab: "Customer Information" },
    { tab: "Shipping Information" },
  ];

  const handleShippingChange = (event) => {
    setSelectedShipping(event.target.value);
  };

  console.log(typeof selectedShipping);

  const calculateSubtotal = () => {
    if (!products || products.length === 0) return 0;

    return products.reduce((acc, product) => {
      return acc + product.price * product.quantity;
    }, 0);
  };

  const subtotal = calculateSubtotal();
  const shipping = selectedShipping | 0; // Calculate shipping cost if needed
  const total = subtotal + shipping;
  const chargees = (1.5/100 * total);
  const newtotal = total + chargees;
  const config = {
    publicKey: "pk_live_83d698738a041b527aec25663b0986125ea001ab",
    text: "Continue with Payment",
    email: userData ? userData?.email : "",
    amount: newtotal * 100,
    currency: "NGN",
    metadata: {
      phone: "",
      name: userData ? userData?.username : "",
    },
    onSuccess: () => {
      toast.success("Thanks for doing business with us! Come back soon!!", {
        autoClose: 1000,
        toastId: 1,
      });
      resetCart();
      navigate("/account");
    },
    onClose: () =>
      toast.danger("Transaction Canceled", {
        autoClose: 1000,
        toastId: 1,
      }),
  };
  const initialValues = {
    items: products,
    first_name: "",
    last_name: "",
    email: userData?.email ? userData?.email : "",
    phone_no: "",
    street_address_1: "",
    city_town: "",
    lga: "",
    state: "",
    ref_code: "",
  };

  const onSubmit = async (payload, actions) => {
    const newPayload = {
      items: JSON.stringify(payload.items),
      first_name: payload.first_name,
      last_name: payload.last_name,
      phone_no: payload.phone_no,
      street_address_1: payload.street_address_1,
      city_town: payload.city_town,
      lga: payload.lga,
      state: payload.state,
      ref_code: payload.ref_code,
    };

    try {
      setLoading(true);
      const res = await Axios.post(requests.productorder, newPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);

      return res.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          toastId: 1,
          autoClose: 1000,
        });
      } else {
        toast.error(error?.message, {
          position: "top-right",
          toastId: 1,
          autoClose: 1000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: checkoutSchema,
      onSubmit,
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const baseUrl = "https://api.devanti.ng";

  const cartCheckout = products?.map((product, index) => {
    let itemImageUrl = product.image.split("https://api.devanti.ng");
    let imageUrl;
    if (itemImageUrl.length == 2) {
      imageUrl = `${baseUrl}${itemImageUrl[1]}`;
    } else {
      imageUrl = `${baseUrl}${product.image}`;
    }

    return (
      <tr key={index} className="cart__table--body__items">
        <td className="cart__table--body__list">
          <div className="product__image two  d-flex align-items-center">
            <div className="product__thumbnail border-radius-5">
              <Link to="/cart">
                <img
                  className="border-radius-5"
                  src={imageUrl}
                  alt={product.name} // Assuming item.product_title is the product title
                />
              </Link>
              <span className="product__thumbnail--quantity">
                {product.quantity}
              </span>
            </div>
            <div className="product__description">
              <h3 className="product__description--name h4">
                <Link to="/cart">{product.name}</Link>
              </h3>
            </div>
          </div>
        </td>
        <td className="cart__table--body__list">
          <span className="cart__price">{`₦${(
            product.price * product.quantity
          ).toFixed(2)}`}</span>
        </td>
      </tr>
    );
  });

  const emptyFirst = values.first_name.length <= 0;
  const emptyLast = values.last_name.length <= 0;
  const emptyPhone_no = values.phone_no.length <= 0;
  const emptyStreet = values.street_address_1.length <= 0;
  const emptyCity = values.city_town.length <= 0;
  const emptyLga = values.lga.length <= 0;
  const emptyState = values.state.length <= 0;
  const emptyCode = values.ref_code.length <= 0;

  const isFormFilled =
    !emptyPhone_no &&
    !emptyFirst &&
    !emptyLast &&
    !emptyStreet &&
    !emptyCity &&
    !emptyLga &&
    !emptyState &&
    !emptyCode;

  return (
    <>
      {/*     
    <!-- Start checkout page area --> */}
      <div className="checkout__page--area">
        <div className="container">
          <div className="checkout__page--inner d-flex">
            <div className="main checkout__mian">
              <header className="main__header checkout__mian--header mb-30">
                <h1 className="main__logo--title">
                  <Link className="logo logo__left mb-20" to="/">
                    <img
                      src={logo}
                      alt="logo"
                      width={"120px"}
                      height={"120px"}
                    />
                  </Link>
                </h1>

                <nav>
                  <ol className="breadcrumb checkout__breadcrumb d-flex">
                    <li className="breadcrumb__item breadcrumb__item--completed d-flex align-items-center">
                      <Link className="breadcrumb__link" to="/cart">
                        Cart
                      </Link>
                      <svg
                        className="readcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.007"
                        height="16.831"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M184 112l144 144-144 144"
                        ></path>
                      </svg>
                    </li>
                    {shippingTabs.map((tab) => (
                      <li
                        key={tab.tab}
                        className="breadcrumb__item breadcrumb__item--current d-flex align-items-center"
                      >
                        <Link
                          className="breadcrumb__link"
                          to="/checkout"
                          onClick={() => setShippingInfo(tab.tab)}
                          style={{
                            color: `${
                              tab.tab === shippingInfo ? "#02278F" : "#999999"
                            }`,
                          }}
                        >
                          {tab.tab}
                        </Link>
                        <svg
                          className="readcrumb__chevron-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.007"
                          height="16.831"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="48"
                            d="M184 112l144 144-144 144"
                          ></path>
                        </svg>
                      </li>
                    ))}
                    <li className="breadcrumb__item breadcrumb__item--completed d-flex align-items-center">
                      <span className="breadcrumb__link">Payment</span>
                      <svg
                        className="readcrumb__chevron-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.007"
                        height="16.831"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M184 112l144 144-144 144"
                        ></path>
                      </svg>
                    </li>
                  </ol>
                </nav>
              </header>

              <aside className=" sidebar d-md-none">
                <div className="cart__table checkout__product--table">
                  <table className="cart__table--inner">
                    <tbody className="cart__table--body">{cartCheckout}</tbody>
                  </table>
                </div>

                <div className="checkout__total">
                  <table className="checkout__total--table">
                    <tbody className="checkout__total--body">
                      <tr className="checkout__total--items">
                        <td className="checkout__total--title text-left">
                          Subtotal{" "}
                        </td>
                        <td className="checkout__total--amount text-right">
                          ₦{subtotal.toFixed(2)}
                        </td>
                      </tr>
                      <tr className="checkout__total--items">
                        <td className="checkout__total--title text-left">
                          Shipping
                        </td>
                        <td className="checkout__total--calculated__text text-right">
                          {selectedShipping === null
                            ? "Calculated at next step"
                            : "₦" + parseInt(selectedShipping).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot className="checkout__total--footer">
                      <tr className="checkout__total--footer__items">
                        <td className="checkout__total--footer__title checkout__total--footer__list text-left">
                          Total{" "}
                        </td>
                        <td className="checkout__total--footer__amount checkout__total--footer__list text-right">
                          ₦{total.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </aside>

              <main className="main__content_wrapper">
                <form onSubmit={handleSubmit}>
                  <>
                    {shippingInfo === "Customer Information" && (
                      <>
                        <div className="checkout__content--step section__shipping--address">
                          <div className="section__header mb-25">
                            <h3 className="section__header--title">
                              Contact Form
                            </h3>
                          </div>
                          <div className="checkout__content--step__inner3 border-radius-5">
                            <div className="checkout__content--input__box--wrapper ">
                              <div className="row">
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list ">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.first_name &&
                                          touched.first_name
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="First name"
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.last_name && touched.last_name
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Last name"
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.email && touched.email
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Email address"
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.phone_no && touched.phone_no
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Phone number"
                                        type="number"
                                        name="phone_no"
                                        value={values.phone_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="checkout__content--step section__shipping--address pt-10">
                          <div className="section__header mb-25">
                            <h3 className="section__header--title">
                              Shipping address
                            </h3>
                            <p className="section__header--desc">
                              Select the address that matches your card or
                              payment method.
                            </p>
                          </div>
                          <div className="checkout__content--step__inner3 border-radius-5">
                            <div className="checkout__content--input__box--wrapper ">
                              <div className="row">
                                <div className="col-12 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.street_address_1 &&
                                          touched.street_address_1
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Address"
                                        type="text"
                                        name="street_address_1"
                                        value={values.street_address_1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>

                                <div className="col-12 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.state && touched.state
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="State"
                                        type="text"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.city_town && touched.city_town
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="City"
                                        type="text"
                                        name="city_town"
                                        value={values.city_town}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.lga && touched.lga
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Local Government"
                                        type="text"
                                        name="lga"
                                        value={values.lga}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-12">
                                  <div className="checkout__input--list">
                                    <label>
                                      <input
                                        className={`checkout__input--field border-radius-5 ${
                                          errors.ref_code && touched.ref_code
                                            ? "border-danger"
                                            : ""
                                        }`}
                                        placeholder="Postal code"
                                        type="text"
                                        name="ref_code"
                                        value={values.ref_code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="checkout__content--step__footer d-flex flex-direction-column align-items-center justify-content-between w-100">
                          <Link
                            to="/cart"
                            className="previous__link--content"
                            style={{ cursor: "pointer", marginLeft: "0" }}
                          >
                            <svg
                              className="readcrumb__chevron-icon"
                              style={{ rotate: "180deg" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.007"
                              height="16.831"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="48"
                                d="M184 112l144 144-144 144"
                              ></path>
                            </svg>{" "}
                            Return to cart
                          </Link>
                          <button
                            type="button"
                            disabled={isFormFilled === false}
                            onClick={() =>
                              setShippingInfo("Shipping Information")
                            }
                            className="continue__shipping--btn primary__btn border-radius-5"
                          >
                            Continue to shipping
                          </button>
                        </div>
                      </>
                    )}
                  </>

                  {shippingInfo === "Shipping Information" && (
                    <>
                      <div className="checkout__content--step__inner3 border-radius-5">
                        <div className="checkout__content--input__box--wrapper">
                          <div className="row">
                            <div className="col-12 mb-12">
                              <div className="checkout__input--list">
                                <div
                                  style={{
                                    width: "100%",
                                    border: "1px solid #999999",
                                    borderRadius: "5px",
                                    backgroundColor: "white",
                                    height: "44px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    padding: "0 8px",
                                    gap: "12px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <h3
                                    style={{
                                      color: "#666666",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Contact:
                                  </h3>
                                  <span
                                    style={{
                                      color: "#666666",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {values.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-12">
                              <div className="checkout__input--list">
                                <div
                                  style={{
                                    width: "100%",
                                    border: "1px solid #999999",
                                    borderRadius: "5px",
                                    backgroundColor: "white",
                                    height: "44px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0 8px",
                                    gap: "12px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <h3
                                    style={{
                                      color: "#666666",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Ship to:
                                  </h3>
                                  <sapn
                                    style={{
                                      color: "#666666",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {values.street_address_1}
                                  </sapn>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "0",
                                    }}
                                    onClick={() =>
                                      setShippingInfo("Customer Information")
                                    }
                                  >
                                    Change
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {loadingShippingOption ? (
                        "Loading Shipping Methods..."
                      ) : (
                        <div className="checkout__content--step section__shipping--address pt-10">
                          <div className="section__header">
                            <h3 className="section__header--title">
                              Shipping Method
                            </h3>
                          </div>

                          {shippingData.length === 0
                            ? "No Shipping Methods"
                            : shippingData.map((option) => (
                                <div
                                  key={option.id}
                                  className="card mt-4"
                                  style={{ padding: "18px 12px !important" }}
                                >
                                  <label
                                    htmlFor={`shipping_option_${option.id}`}
                                    className="card-body d-flex flex-column flex-md-row position-relative align-items-start gap-3 p-4"
                                    style={{
                                      cursor: "pointer",
                                      userSelect: "none",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id={`shipping_option_${option.id}`}
                                      name="shipping_method"
                                      value={String(option.price)}
                                      checked={
                                        selectedShipping ===
                                        String(option.price)
                                      }
                                      onChange={handleShippingChange}
                                      className="mt-3"
                                    />
                                    <p
                                      className="card-text"
                                      style={{
                                        fontWeight: 600,
                                        color: "#666666",
                                        fontSize: "16px",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {option.location}
                                    </p>
                                    <p
                                      className="card-text"
                                      style={{ marginLeft: "auto" }}
                                    >
                                      ₦{option.price.toFixed(2)}
                                    </p>
                                  </label>
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "0 12px 14px",
                                      margin: "0",
                                    }}
                                  >
                                    {option.description}
                                  </div>
                                </div>
                              ))}
                        </div>
                      )}
                      <div className="checkout__content--step__footer d-flex flex-direction-column align-items-center justify-content-between w-100">
                        <span
                          className="previous__link--content"
                          style={{ cursor: "pointer", marginLeft: "0" }}
                          onClick={() =>
                            setShippingInfo("Customer Information")
                          }
                        >
                          <svg
                            className="readcrumb__chevron-icon"
                            style={{ rotate: "180deg" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.007"
                            height="16.831"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="48"
                              d="M184 112l144 144-144 144"
                            ></path>
                          </svg>{" "}
                          Return to Customer Information
                        </span>
                        <button
                          type="submit"
                          onClick={() => handleSubmit()}
                          className="continue__shipping--btn primary__btn px-0"
                        >
                          {loading === true ? (
                            "Processing..."
                          ) : (
                            <PaystackButton
                              {...config}
                              className="continue__shipping--btn primary__btn border-radius-5"
                            />
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </main>
              <footer className="main__footer checkout__footer">
                <p className="copyright__content">
                  Copyright © 2024{" "}
                  <Link
                    className="copyright__content--link text__primary"
                    to="/"
                  >
                    Devanti
                  </Link>{" "}
                  . All Rights Reserved.
                </p>
              </footer>
            </div>
            <aside className="checkout__sidebar sidebar">
              <div className="cart__table checkout__product--table">
                <table className="cart__table--inner">
                  <tbody className="cart__table--body">{cartCheckout}</tbody>
                </table>
              </div>

              <div className="checkout__total">
                <table className="checkout__total--table">
                  <tbody className="checkout__total--body">
                    <tr className="flex checkout__total--items">
                      <td className="checkout__total--title text-left">
                        Subtotal{" "}
                      </td>
                      <td className="checkout__total--amount text-right">
                        ₦{subtotal.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="checkout__total--items">
                      <td className="checkout__total--title text-left">
                        Shipping
                      </td>
                      <td className="checkout__total--calculated__text text-right">
                        {selectedShipping === null
                          ? "Calculated at next step"
                          : "₦" + parseInt(selectedShipping).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot className="checkout__total--footer">
                    <tr className="checkout__total--footer__items">
                      <td className="checkout__total--footer__title checkout__total--footer__list text-left">
                        Total{" "}
                      </td>
                      <td className="checkout__total--footer__amount checkout__total--footer__list text-right">
                        ₦{total.toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </aside>
          </div>
        </div>
      </div>
      {/* <!-- End checkout page area --> */}
    </>
  );
};

export default Checkout;
