import { useEffect, useState } from "react";
import img1 from "../assets/img/product/product1.svg";

import img2 from "../assets/img/product/small-product10.png";
import img4 from "../assets/img/product/small-product10.png";
import img5 from "../assets/img/product/small-product10.png";
import img6 from "../assets/img/product/small-product10.png";
import img7 from "../assets/img/product/small-product10.png";
import img8 from "../assets/img/product/product1.svg";
import { Axios } from "../config";
import requests from "../lib/requests";
import { Link } from "react-router-dom";

const Feauture = () => {
  const [newArrivals, setNewArrivals] = useState([]);

  useEffect(() => {
    GetArriavlProducts();
  }, []);

  const GetArriavlProducts = async () => {
    try {
      const res = await Axios.get(requests.products);
      setNewArrivals(res.data);
      return res.data;
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  };

  console.log("Arrails", newArrivals);

  const isArrivalImage =
    newArrivals && newArrivals.filter((item) => item.is_newArrival)[0];

  const isBestSellerImage =
    newArrivals && newArrivals.filter((item) => item.is_bestSeller)[0];

  return (
    <>
      <section className="product__section section--padding color-scheme-2 pt-0">
        <div className="container-fluid">
          <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb--n30">
            <div className="col mb-30">
              <div className="product__grid--heading">
                <h2 className="product__grid--heading__maintitle position__relative">
                  New Arrivals
                </h2>
              </div>
              <div className="banner__items position__relative myawesomeClass">
                {isArrivalImage && (
                  <Link
                    className="banner__items--thumbnail width-100 "
                    to={`/productdetails/${isArrivalImage.slug}`}
                    state={{
                      id: isArrivalImage.id,
                      image: `https://api.devanti.ng${isArrivalImage.image}`,
                      price: isArrivalImage.price,
                      name: isArrivalImage.name,
                      description: isArrivalImage.description,
                      // Add other necessary details here
                    }}
                  >
                    <img
                      className="banner__items--thumbnail__img width-100"
                      src={`https://api.devanti.ng${isArrivalImage.image}`}
                      alt={isArrivalImage.name}
                    />
                  </Link>
                )}
              </div>
            </div>
            <div className="col mb-30">
              <div className="product__grid--wrapper">
                <div
                  className="product__grid--inner"
                  style={{ position: "relative", top: "40px" }}
                >
                  {newArrivals.length === 0 ? (
                    <p>No Arrivals products available</p>
                  ) : newArrivals.filter((item) => item.is_newArrival)
                      .length === 0 ? (
                    <p>No Arrivals products available</p>
                  ) : (
                    newArrivals
                      .filter((item) => item.is_newArrival)
                      .slice(0, 3)
                      .map((item) => {
                        return (
                          <Link
                            key={item.id}
                            to={`/productdetails/${item.slug}`}
                            state={{
                              id: item.id,
                              image: `https://api.devanti.ng${item.image}`,
                              price: item.price,
                              name: item.name,
                              description: item.description,
                              qty: item.qty,
                              discount: item.discount,
                              old_price: item.old_price,
                              // Add other necessary details here
                            }}
                            className="product__items product__items--grid d-flex align-items-center"
                          >
                            <div className="product__items--grid__thumbnail position__relative">
                              <div className="product__items--link">
                                <img
                                  className="product__items--img product__primary--img"
                                  src={`https://api.devanti.ng${item.image}`}
                                  alt={item.name}
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="product__items--grid__content">
                              <h3 className="product__items--content__title h4">
                                {item.name}
                              </h3>
                              <div className="product__items--price">
                                <span className="current__price">
                                  ₦{item.price}
                                </span>
                              </div>
                              <ul className="rating product__rating d-flex">
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
            <div className="col mb-35 sec">
              <div className="banner__items position__relative">
                <div className="product__grid--heading">
                  <h2 className="product__grid--heading__maintitle position__relative">
                    Best Sellers
                  </h2>
                </div>
                <div className="banner__items position__relative myawesomeClass">
                  {isBestSellerImage && (
                    <Link
                      className="banner__items--thumbnail width-100 "
                      to={`/productdetails/${isBestSellerImage.slug}`}
                      state={{
                        id: isBestSellerImage.id,
                        image: `https://api.devanti.ng${isBestSellerImage.image}`,
                        price: isBestSellerImage.price,
                        name: isBestSellerImage.name,
                        description: isBestSellerImage.description,
                        // Add other necessary details here
                      }}
                    >
                      <img
                        className="banner__items--thumbnail__img width-100"
                        src={`https://api.devanti.ng${isBestSellerImage.image}`}
                        alt={isBestSellerImage.name}
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col mb-30">
              <div
                className="product__grid--wrapper"
                style={{ position: "relative", top: "40px" }}
              >
                <div className="product__grid--inner">
                  {newArrivals.length === 0 ? (
                    <p>No Best Sellers products available</p>
                  ) : newArrivals.filter((item) => item.is_bestSeller)
                      .length === 0 ? (
                    <p>No Best Sellers products available</p>
                  ) : (
                    newArrivals
                      .filter((item) => item.is_bestSeller)
                      .slice(0, 3)
                      .map((item) => {
                        return (
                          <Link
                            key={item.id}
                            to={`/productdetails/${item.slug}`}
                            state={{
                              id: item.id,
                              image: `https://api.devanti.ng${item.image}`,
                              price: item.price,
                              name: item.name,
                              description: item.description,
                              // Add other necessary details here
                            }}
                            className="product__items product__items--grid d-flex align-items-center"
                          >
                            <div className="product__items--grid__thumbnail position__relative">
                              <div className="product__items--link">
                                <img
                                  className="product__items--img product__primary--img"
                                  src={`https://api.devanti.ng${item.image}`}
                                  alt={item.name}
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="product__items--grid__content">
                              <h3 className="product__items--content__title h4">
                                {item.name}
                              </h3>
                              <div className="product__items--price">
                                <span className="current__price">
                                  ₦{item.price}
                                </span>
                              
                              </div>
                              <ul className="rating product__rating d-flex">
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                                <li className="rating__list">
                                  <span className="rating__list--icon">
                                    <svg
                                      className="rating__list--icon__svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.105"
                                      height="14.732"
                                      viewBox="0 0 10.105 9.732"
                                    >
                                      <path
                                        data-name="star - Copy"
                                        d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                        transform="translate(0 -0.018)"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        );
                      })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feauture;
