import { create } from "zustand";

const UseCartStore = create((set) => {
  // Retrieve cart items from localStorage on store initialization
  const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  set(() => ({
    cartItems: storedCartItems,
  }));

  return {
    cartItems: storedCartItems,
    addToCart: (product) =>
      set((state) => {
        const updatedCart = [...state.cartItems, { ...product, quantity: 1 }];
        localStorage.setItem("cartItems", JSON.stringify(updatedCart));
        return { cartItems: updatedCart };
      }),

    searchQuery: "",
    setSearchQuery: (query) => set({ searchQuery: query }),

    increaseQuantity: (productId) =>
      set((state) => {
        const updatedCart = state.cartItems.map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Update localStorage
        return { cartItems: updatedCart };
      }),

    decreaseQuantity: (productId) =>
      set((state) => {
        const updatedCart = state.cartItems.map((item) =>
          item.id === productId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Update localStorage
        return { cartItems: updatedCart };
      }),

    removeItem: (productId) =>
      set((state) => {
        const updatedCart = state.cartItems.filter(
          (item) => item.id !== productId
        );
        localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Update localStorage
        return { cartItems: updatedCart };
      }),
    clearCart: () =>
      set(() => {
        localStorage.removeItem("cartItems");
        return { cartItems: [] };
      }),

    getItemCount: () => {
      return storedCartItems.length; // Note: This might need to be updated based on the actual cartItems in state
    },
    // You can add more cart-related actions here like updating quantity, etc.
  };
});

export default UseCartStore;
