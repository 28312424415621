import React, { useState } from "react";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Axios } from "../config";
import requests from "../lib/requests";
import useAuthStore from "../store/store";

import { jwtDecode } from "jwt-decode";
const Login = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const store = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/";
  
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (payload, actions) => {
    if (errors.email || errors.password) {
      toast.error("Please fill out all fields correctly");
      return;
    }

    const data = {
      email: values.email,
      password: values.password,
    };
    try {
      const res = await Axios.post(requests.token, data);
      store.setNewToken(res.data.refresh);
      store.setToken(res.data.access);

      const decoded = jwtDecode(res.data.access);
      store.setAuthUser(decoded?.username);
      toast.success(res?.data.message);
      navigate(from);   
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data.message);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <>
      <main className="main__content_wrapper">
        <div className="login__section section--padding">
          <div className="container">
            <form
              action="#"
              onSubmit={handleSubmit}
              className={`${
                errors.email && errors.password ? "gap-2" : "gap-4"
              }`}
            >
              <div className="login__section--inner">
                <div className="account__login">
                  <div className="account__login--header mb-25">
                    <h2 className="account__login--header__title h3 mb-10">
                      Login
                    </h2>
                    <p className="account__login--header__desc">
                      Login if you area a returning customer.
                    </p>
                  </div>
                  <div className="account__login--inner">
                    <input
                      className="account__login--input"
                      placeholder="Email Address"
                      id="email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={getError("email")}
                    />
                    <input
                      className={` account__login--input ${
                        errors.password && touched.password ? "password" : ""
                      }`}
                      placeholder="Password"
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {errors.password && touched.password && (
                      <div className="error">{errors.password}</div>
                    )}
                    <div className="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                      <div className="account__login--remember position__relative">
                        <input
                          className="checkout__checkbox--input"
                          id="check1"
                          type="checkbox"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label
                          className="checkout__checkbox--label login__remember--label"
                          for="check1"
                        >
                          Remember me
                        </label>
                      </div>
                      <button className="account__login--forgot" type="submit">
                        Forgot Your Password?
                      </button>
                    </div>
                    <button
                      className="account__login--btn primary__btn"
                      type="submit"
                    >
                      Login
                    </button>
                   
                    <p className="account__login--signup__text">
                      Don,t Have an Account?{" "}
                      <Link to="/signin">
                        <button type="submit">Sign up now</button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- End login section  --> */}
      </main>
      <ToastContainer />
    </>
  );
};

export default Login;
