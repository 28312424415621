import { Link } from "react-router-dom";
import useAuthStore from "../store/store";
import { useEffect, useState } from "react";
import { Axios } from "../config";
import requests from "../lib/requests";
import TimeFormatter from "../util/TimeFormatter";

const OrderHistory = () => {
  const { authUser, token } = useAuthStore();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // const myObject = {
  //   city_town: "Natus consectetur ni",
  //   created_at: "2023-12-17T20:35:41.834718Z",
  //   first_name: "Caleb",
  //   id: 2,
  //   items: null,
  //   last_name: "William",
  //   lga: "Et est possimus er",
  //   order_status: "Processing",
  //   paid: false,
  //   phone_no: "293832829190",
  //   ref_code: "Sint recusandae Re",
  //   state: "In voluptate in erro",
  //   street_address_1: "216 West Nobel Freeway",
  //   street_address_2: null,
  // };

  const getUserOrder = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(requests.productorder, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setOrderData(response.data);
      return response.data;
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserOrder();
  }, []);

  const columns = [
    {
      field: "orderId",
      header: "Order",
    },
    {
      field: "date",
      header: "Date",
    },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "total",
      header: "Total",
    },
    {
      field: "action",
      header: "Action",
    },
  ];

  const tableActions = orderData?.map((item) => {
    const itemsArray = JSON.parse(item.items);

    // Calculate the total value for each order
    const totalValue = itemsArray?.reduce(
      (total, item) => total + item.price * item.qty,
      0
    );
    return {
      orderId: <span style={{ paddingLeft: "20px" }}>{item.id}</span>,
      date: (
        <span>
          <TimeFormatter createdAt={item.created_at} />
        </span>
      ),
      status: <span>{item.order_status}</span>,
      total: <span>₦{totalValue}</span>,
      action: (
        <div className="w-100 d-flex align-items-end justify-content-end">
          <Link
            to={`/account/orderdetail/${item.id}`}
            state={{
              id: item.id,
              data: itemsArray,
              // Add other necessary details here
            }}
            className="btn btn-lg text-white leadding-3 py-2 px-3 me-2"
            style={{ backgroundColor: "#ffaf06" }}
          >
            View
          </Link>
        </div>
      ),
    };
  });

  return (
    <>
      <div className="account__wrapper">
        <div className="account__content">
          <p className="account__welcome--text" style={{ fontSize: "12px" }}>
            Hello, {authUser} welcome to your dashboard!
          </p>
          <div className="" style={{ display: "flex" }}>
            <span className="account__content--title h3 mb-20">
              Orders History
            </span>
          </div>
          {loading ? (
            "Loading..."
          ) : errorMsg !== "" ? (
            <p style={{ color: "red", fontSize: "24px" }}>Error : {errorMsg}</p>
          ) : (
            <>
              <div className="account__table--area">
                <table className="account__table">
                  <thead className="account__table--header">
                    <tr
                      className="account__table--header__child"
                      style={{ border: "none" }}
                    >
                      {columns &&
                        columns.map((head, i) => (
                          <td
                            key={i}
                            className="account__table--header__child--items"
                          >
                            {head.header}
                          </td>
                        ))}
                    </tr>
                  </thead>
                  <tbody className="account__table--body mobile__none">
                    {tableActions &&
                      tableActions.map((row, i) => (
                        <tr key={i} className="account__table--body__child">
                          {columns?.map((col, i) => (
                            <td
                              key={i}
                              className="account__table--body__child--items"
                            >
                              {row[col.field]}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
