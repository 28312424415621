import { useEffect, useState } from "react";

import img7 from "../assets/img/other/comment-thumb1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useAuthStore from "../store/store";
import { toast } from "react-toastify";
import useUpdateCartStore from "../store/cart";
import { Axios } from "../config";
import requests from "../lib/requests";
const ProductDetails = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useUpdateCartStore();
  const [pendingCartItem, setPendingCartItem] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const [quantity, setQuantity] = useState(1); // Added quantity state

  const location = useLocation();
  const { token } = useAuthStore();
  const {
    id,
    image,
    price,
    discount,
    old_price,
    name,
    description,
    additional_info,
    qty,
    image1,
    image2,
    image3,
    image4,
  } = location.state || {};
  const images = [ image1, image2, image3, image4].filter(Boolean);
  const navigate = useNavigate();

  const increaseQuantity = () => {
    // Increment the quantity by 1
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    // Decrease the quantity by 1 if greater than 1
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddToCart = (product) => {
    const productWithQuantity = { ...product, quantity: quantity };
    console.log(product);
    if (quantity > qty) {
      toast.warning("Available quantity exceeded");
    } else {
      addToCart(productWithQuantity);
      toast.success(`${name} added to cart`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    // Check if the user is authenticated and there's a pending item after successful login
    if (token && pendingCartItem) {
      // Add the pending item to the cart after login
      addToCart(pendingCartItem);
      // Optionally add additional logic after adding pending item to cart

      // Reset the pending item state after adding to cart
      setPendingCartItem(null);
    }
  }, [token, addToCart, pendingCartItem]);

  const handleBuyNow = () => {
    const productDetails = {
      id,
      image,
      price,
      name,
      description,
      quantity,
    };

    // Navigate to checkout page with product details
    navigate("/checkout", {
      state: {
        products: [productDetails], // You can pass multiple products in an array if needed
      },
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false, // Set to true for automatic sliding
    autoplaySpeed: 2000, // Set the interval (in milliseconds)
    responsive: [
      {
        breakpoint: 576, // Adjust this breakpoint for your specific mobile screen size
        settings: {
          slidesToShow: 2, // Change slidesToShow to 2 for mobile screens
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  useEffect(() => {
    GetProducts();
  }, []);

  const GetProducts = async () => {
    try {
      const res = await Axios.get(requests.products);
      setProducts(res.data); // Update products state with fetched data
      console.log(res.data);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  };

  return (
    <>
      <main className="main__content_wrapper">
        {/* <!-- Start product details section --> */}
        <section className="product__details--section section--padding">
          <div className="container">
            <div className="row row-cols-lg-2 row-cols-md-2">
              <div className="col">
                <div className="product__details--media">
                  <div className="product__media--preview  swiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="product__media--preview__items">
                          <a
                            className="product__media--preview__items--link glightbox"
                            data-gallery="product-media-preview"
                            href="assets/img/product/big-product2.jpg"
                          >
                            <img
                              className="product__media--preview__items--img"
                              src={image}
                              alt="product-media-img"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="product__media--nav swiper">
                    <div className="swiper-wrapper">
                      <Slider {...settings}>
                        <div className="swiper-slide"></div>
                        <div className="swiper-slide"></div>
                        <div className="swiper-slide"></div>
                        <div className="swiper-slide"></div>
                        <div className="swiper-slide"></div>
                      </Slider>
                    </div>
                    {/* <div className="swiper__nav--btn swiper-button-next"></div>
                    <div className="swiper__nav--btn swiper-button-prev"></div> */}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="product__details--info">
                  <form action="#">
                    <h2 className="product__details--info__title mb-15">
                      {name}
                    </h2>
                    <div className="product__details--info__price mb-10">
                      <span className="current__price">₦{price}</span>
                      {discount != 0 && (
                              <>
                              <span className="price__divided"></span>
                              <span className="old__price">{old_price}</span>
                              </>
                            )}
                    </div>

                    <p className="product__details--info__desc mb-15">
                      {description.substring(0, 500)}{" "}
                      {description.length >= 20 && "..."}
                    </p>
                    <div className="product__variant">
                      <div className="product__variant--list quantity d-flex align-items-center mb-20">
                        <div className="quantity__box">
                          <button
                            type="button"
                            className="quantity__value quickview__value--quantity decrease"
                            aria-label="quantity value"
                            value="Decrease Value"
                            onClick={decreaseQuantity}
                          >
                            -
                          </button>
                          <label>
                            <input
                              type="number"
                              className="quantity__number quickview__value--number"
                              value={quantity}
                            />
                          </label>
                          <button
                            type="button"
                            className="quantity__value quickview__value--quantity increase"
                            aria-label="quantity value"
                            value="Increase Value"
                            onClick={increaseQuantity}
                            disabled={quantity >= qty}
                          >
                            +
                          </button>
                        </div>
                        <button
                          className="quickview__cart--btn primary__btn"
                          type="button"
                          onClick={() => {
                            handleAddToCart(location.state);
                          }}
                          disabled={qty == 0}
                        >
                          {qty == 0 ? "Out of stock" : "Add To Cart"}
                        </button>
                      </div>
                      <div
                        className="product__variant--list mb-15"
                        to="checkout"
                      >
                        <button
                          className="variant__buy--now__btn primary__btn"
                          type="submit"
                          onClick={handleBuyNow}
                          disabled={qty == 0}
                        >
                          Buy it now
                        </button>
                      </div>

                      <div className="quickview__social d-flex align-items-center mb-15">
                        <label className="quickview__social--title">
                          Social Share:
                        </label>
                        <ul className="quickview__social--wrapper mt-0 d-flex">
                          <li className="quickview__social--list">
                            <a
                              className="quickview__social--icon"
                              target="_blank"
                              href="https://www.facebook.com"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7.667"
                                height="16.524"
                                viewBox="0 0 7.667 16.524"
                              >
                                <path
                                  data-name="Path 237"
                                  d="M967.495,353.678h-2.3v8.253h-3.437v-8.253H960.13V350.77h1.624v-1.888a4.087,4.087,0,0,1,.264-1.492,2.9,2.9,0,0,1,1.039-1.379,3.626,3.626,0,0,1,2.153-.6l2.549.019v2.833h-1.851a.732.732,0,0,0-.472.151.8.8,0,0,0-.246.642v1.719H967.8Z"
                                  transform="translate(-960.13 -345.407)"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="visually-hidden">Facebook</span>
                            </a>
                          </li>
                          <li className="quickview__social--list">
                            <a
                              className="quickview__social--icon"
                              target="_blank"
                              href="https://twitter.com"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.489"
                                height="13.384"
                                viewBox="0 0 16.489 13.384"
                              >
                                <path
                                  data-name="Path 303"
                                  d="M966.025,1144.2v.433a9.783,9.783,0,0,1-.621,3.388,10.1,10.1,0,0,1-1.845,3.087,9.153,9.153,0,0,1-3.012,2.259,9.825,9.825,0,0,1-4.122.866,9.632,9.632,0,0,1-2.748-.4,9.346,9.346,0,0,1-2.447-1.11q.4.038.809.038a6.723,6.723,0,0,0,2.24-.376,7.022,7.022,0,0,0,1.958-1.054,3.379,3.379,0,0,1-1.958-.687,3.259,3.259,0,0,1-1.186-1.666,3.364,3.364,0,0,0,.621.056,3.488,3.488,0,0,0,.885-.113,3.267,3.267,0,0,1-1.374-.631,3.356,3.356,0,0,1-.969-1.186,3.524,3.524,0,0,1-.367-1.5v-.057a3.172,3.172,0,0,0,1.544.433,3.407,3.407,0,0,1-1.1-1.214,3.308,3.308,0,0,1-.4-1.609,3.362,3.362,0,0,1,.452-1.694,9.652,9.652,0,0,0,6.964,3.538,3.911,3.911,0,0,1-.075-.772,3.293,3.293,0,0,1,.452-1.694,3.409,3.409,0,0,1,1.233-1.233,3.257,3.257,0,0,1,1.685-.461,3.351,3.351,0,0,1,2.466,1.073,6.572,6.572,0,0,0,2.146-.828,3.272,3.272,0,0,1-.574,1.083,3.477,3.477,0,0,1-.913.8,6.869,6.869,0,0,0,1.958-.546A7.074,7.074,0,0,1,966.025,1144.2Z"
                                  transform="translate(-951.23 -1140.849)"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="visually-hidden">Twitter</span>
                            </a>
                          </li>
                          <li className="quickview__social--list">
                            <a
                              className="quickview__social--icon"
                              target="_blank"
                              href="https://www.instagram.com"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.497"
                                height="16.492"
                                viewBox="0 0 19.497 19.492"
                              >
                                <path
                                  data-name="Icon awesome-instagram"
                                  d="M9.747,6.24a5,5,0,1,0,5,5A4.99,4.99,0,0,0,9.747,6.24Zm0,8.247A3.249,3.249,0,1,1,13,11.238a3.255,3.255,0,0,1-3.249,3.249Zm6.368-8.451A1.166,1.166,0,1,1,14.949,4.87,1.163,1.163,0,0,1,16.115,6.036Zm3.31,1.183A5.769,5.769,0,0,0,17.85,3.135,5.807,5.807,0,0,0,13.766,1.56c-1.609-.091-6.433-.091-8.042,0A5.8,5.8,0,0,0,1.64,3.13,5.788,5.788,0,0,0,.065,7.215c-.091,1.609-.091,6.433,0,8.042A5.769,5.769,0,0,0,1.64,19.341a5.814,5.814,0,0,0,4.084,1.575c1.609.091,6.433.091,8.042,0a5.769,5.769,0,0,0,4.084-1.575,5.807,5.807,0,0,0,1.575-4.084c.091-1.609.091-6.429,0-8.038Zm-2.079,9.765a3.289,3.289,0,0,1-1.853,1.853c-1.283.509-4.328.391-5.746.391S5.28,19.341,4,18.837a3.289,3.289,0,0,1-1.853-1.853c-.509-1.283-.391-4.328-.391-5.746s-.113-4.467.391-5.746A3.289,3.289,0,0,1,4,3.639c1.283-.509,4.328-.391,5.746-.391s4.467-.113,5.746.391a3.289,3.289,0,0,1,1.853,1.853c.509,1.283.391,4.328.391,5.746S17.855,15.705,17.346,16.984Z"
                                  transform="translate(0.004 -1.492)"
                                  fill="currentColor"
                                />
                              </svg>
                              <span className="visually-hidden">Instagram</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div style={{ marginTop: "70px" }}>
                        <Slider {...settings}>
                          {images.map((img, index) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                key={index}
                                src={img}
                                alt={`Image ${index}`}
                                style={{
                                  width: "94px",
                                  height: "91px",
                                  borderRadius: "13px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                      {/* <div className="swiper__nav--btn swiper-button-next"></div>
                    <div className="swiper__nav--btn swiper-button-prev"></div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product__details--tab__section section--padding">
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <ul className="product__details--tab d-flex mb-30">
                  <li
                    className={`product__details--tab__list ${
                      activeTab === "description" ? "active" : ""
                    } `}
                    data-toggle="tab"
                    data-target="#description"
                    onClick={() => handleTabClick("description")}
                  >
                    Description
                  </li>

                  <li
                    className={`product__details--tab__list ${
                      activeTab === "information" ? "active" : ""
                    }`}
                    data-toggle="tab"
                    data-target="#information"
                    onClick={() => handleTabClick("information")}
                  >
                    Additional Info
                  </li>
                </ul>
                <div className="product__details--tab__inner border-radius-10">
                  <div className="tab_content">
                    <div
                      id="description"
                      className={`tab_pane ${
                        activeTab === "description" ? "active show" : ""
                      }`}
                    >
                      <div className="product__tab--content">
                        <div className="product__tab--content__step mb-30">
                          <h2 className="product__tab--content__title h4 mb-10">
                            {name}
                          </h2>
                          <p className="product__tab--content__desc">
                            {description}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      id="information"
                      class={`tab_pane ${
                        activeTab === "information" ? "active show" : ""
                      }`}
                    >
                      <div className="product__tab--conten">
                        <div className="product__tab--content__step mb-30">
                          <h2 className="product__tab--content__title h4 mb-10">
                            {name}
                          </h2>
                          <p className="product__tab--content__desc">
                            {additional_info}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product__section product__section--style3 section--padding">
          <div className="container-fluid product3__section--container">
            <div className="section__heading text-center mb-50">
              <h2 className="section__heading--maintitle">You may also like</h2>
            </div>
            <div className="product__section--inner product__swiper--column4__activation swiper">
              <div className="">
                <Slider {...settings}>
                  {products?.slice(0, 10).map((product) => (
                    <div key={product.id} className="swiper-slide">
                      <div
                        className="product__items"
                        style={{ margin: "0px 10px" }}
                      >
                        <div className="product__items--thumbnail">
                          <Link
                            className="product__items--link"
                            to={`/productdetails/${product.slug}`}
                            state={{
                              id: product.id,
                              image: `https://api.devanti.ng${product.image}`,
                              price: product.price,
                              name: product.name,
                              description: product.description,
                              additional_info: product.additional_info,
                              qty: product.qty,
                              // Add other necessary details here
                            }}
                          >
                            <img
                              className="product__items--img product__primary--img"
                              src={`https://api.devanti.ng${product.image}`}
                              alt={product.name}
                            />
                          </Link>
                          <div className="product__badge">
                            <span className="product__badge--items sale">
                              Sale
                            </span>
                          </div>
                        </div>
                        <div
                          className="product__items--content"
                          style={{ margin: "0px 30px" }}
                        >
                          <span className="product__items--content__subtitle">
                            {product.name}
                          </span>
                          <h3 className="product__items--content__title h4">
                            {product.name}
                          </h3>
                          <div className="product__items--price">
                            <span className="current__price">
                              ₦{product.price}
                            </span>
                            
                            {product.discount != 0 && (
                              <>
                              <span className="price__divided"></span>
                              <span className="old__price">{product.old_price}</span>
                              </>
                            )}
                          </div>
                          <ul className="rating product__rating d-flex">
                            <li className="rating__list">
                              <span className="rating__list--icon">
                                <svg
                                  className="rating__list--icon__svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.105"
                                  height="14.732"
                                  viewBox="0 0 10.105 9.732"
                                >
                                  <path
                                    data-name="star - Copy"
                                    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                    transform="translate(0 -0.018)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </li>
                            <li className="rating__list">
                              <span className="rating__list--icon">
                                <svg
                                  className="rating__list--icon__svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.105"
                                  height="14.732"
                                  viewBox="0 0 10.105 9.732"
                                >
                                  <path
                                    data-name="star - Copy"
                                    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                    transform="translate(0 -0.018)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </li>
                            <li className="rating__list">
                              <span className="rating__list--icon">
                                <svg
                                  className="rating__list--icon__svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.105"
                                  height="14.732"
                                  viewBox="0 0 10.105 9.732"
                                >
                                  <path
                                    data-name="star - Copy"
                                    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                    transform="translate(0 -0.018)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </li>
                            <li className="rating__list">
                              <span className="rating__list--icon">
                                <svg
                                  className="rating__list--icon__svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.105"
                                  height="14.732"
                                  viewBox="0 0 10.105 9.732"
                                >
                                  <path
                                    data-name="star - Copy"
                                    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                    transform="translate(0 -0.018)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </li>
                            <li className="rating__list">
                              <span className="rating__list--icon">
                                <svg
                                  className="rating__list--icon__svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.105"
                                  height="14.732"
                                  viewBox="0 0 10.105 9.732"
                                >
                                  <path
                                    data-name="star - Copy"
                                    d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                    transform="translate(0 -0.018)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </li>
                          </ul>
                          <ul className="product__items--action d-flex">
                            <li className="product__items--action__list">
                              <div
                                className="product__items--action__btn add__to--cart"
                                onClick={() => {
                                  const productWithQuantity = {
                                    ...product,
                                    quantity: 1,
                                  };

                                  addToCart(productWithQuantity);

                                  toast.success(
                                    `${product.name} added to cart`,
                                    {
                                      position: "top-right",
                                      autoClose: 3000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <svg
                                  className="product__items--action__btn--svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22.51"
                                  height="20.443"
                                  viewBox="0 0 14.706 13.534"
                                >
                                  <g transform="translate(0 0)">
                                    <g>
                                      <path
                                        data-name="Path 16787"
                                        d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                                        transform="translate(0 -463.248)"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        data-name="Path 16788"
                                        d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                                        transform="translate(-1.191 -466.622)"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        data-name="Path 16789"
                                        d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                                        transform="translate(-2.875 -466.622)"
                                        fill="currentColor"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                                <span className="add__to--cart__text">
                                  {" "}
                                  + Add to cart
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductDetails;
