import React from "react";
import img from "../assets/img/icon/Vector.png";
import img2 from "../assets/img/icon/Ellipse.png";
import img3 from "../assets/img/icon/Medmall.png";
const DashboardTestimonial = () => {
  return (
    <>
      <div className="account__wrapper">
        <div className="account__content">
          <span
            className="mb-20"
            style={{ color: "#3C3C3C", fontSize: "20px" }}
          >
            Create Testimonials
          </span>
          <div className="account__table">
            {/* <span class="mb-20" style={{}}>
              Set parameters
            </span> */}

            <div className="testimonial-container">
              <div>
                <div className="input-testimonial">
                  <span>Upload Customer’s Image</span> <br />
                  <input type="image" />
                </div>

                <div className="input-testimonial">
                  <span>Customer Name</span> <br />
                  <input type="text" placeholder="Enter Customer’s name" />
                </div>

                <div className="input-testimonial">
                  <span>Customer Occupation </span> <br />
                  <input
                    type="text"
                    placeholder="Enter Customer’s Occupations"
                  />
                </div>

                <div className="input-testimonial">
                  <textarea
                    name="message"
                    placeholder="Add note"
                    id="message"
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div>
                <img src={img} alt="" />
              </div>

              <div className="testimonial-col2">
                <span className="">Preview</span>

                <div className="testimonial__bg dashboard">
                  <p className="text-center p-3 testimoni">Testmonial</p>

                  <div className="testimonial__items--style2__thumbnail mb-10">
                    <img
                      className="testimonial__items--style2__thumbnail--img border-radius-50"
                      style={{ margin: "10px 24rem" }}
                      src={img2}
                      alt=""
                    />
                    <div className="testimonial__items--content text-center">
                      <h2 className="testimonial__items--title text-black h3 text-center">
                        Ibunkunoluwa
                      </h2>
                      <span className="testimonial__items--subtitle text-black">
                        A public servant
                      </span>
                      <p
                        className="testimonial__items--desc style2 text-black text-center"
                        style={{ width: "420px", fontSize: "10px" }}
                      >
                        I have been a loyal customer of Medmall Pharmacy for
                        years, and I can't speak highly enough about their
                        exceptional service and knowledgeable staff. Whether I
                        need prescription medications or advice on
                        over-the-counter remedies, they are always there to help
                        with a smile.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="btn-container">
                  <button className="publish">Publish</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardTestimonial;
