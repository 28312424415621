import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img2 from "../assets/img/other/image.svg";
import { Axios } from "../config";
import toast from "react-hot-toast";
import requests from "../lib/requests";

const About = () => {
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTeam = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(requests.teams);
      setTeamData(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch Team", {
        toastId: 1,
        duration: "300",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeam();
  }, []);
  return (
    <>
      <main className="main__content_wrapper">
        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
          <div className="container"></div>
        </section>
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- Start about section --> */}
        <section className="about__section section--padding mb-95">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-6">
                {/* <div className="about__thumb--items position__relative"> */}
                <img
                  className="img-fluid border-radius-5 display-block"
                  src={img2}
                  alt="about-thumb"
                  style={{ height: "80%", width: "100%" }}
                />
                {/* </div> */}
              </div>
              <div className="col-lg-6">
                <div className="about__content">
                  <span
                    className="about__content--subtitle text__secondary mb-20"
                    style={{ fontSize: "30px" }}
                  >
                    Why Choose us
                  </span>
                  <h2 className="about__content--maintitle mb-25">
                    We do not just sell quality and affordable clothing and
                    jewelry accessories. Our aim is to make you re-gain the
                    confidence of being you.
                  </h2>
                  <p className="about__content--desc mb-20">
                    Welcome to Devanti Couture where style meets sophistication
                    and every accessory tells a story. As a premier destination
                    for fashion-forward individuals, we pride ourselves on
                    curating a unique collection of jewelry, accessories, and
                    apparel that exude elegance and charm. With a keen eye for
                    the latest trends and a dedication to quality craftsmanship,
                    we offer our customers a shopping experience like no other."
                    At Devanti, we understand that fashion is more than just
                    clothing - it's a form of self-expression. That's why our
                    diverse range of products caters to every style and
                    occasion, whether you're looking for a statement piece to
                    elevate your evening look or everyday essentials to enhance
                    your wardrobe. With a commitment to excellence and a passion
                    for innovation, we strive to exceed your expectations with
                    every purchase. Choose us for unparalleled style,
                    exceptional quality, and a personalized shopping experience.
                    Whether you're shopping for yourself or searching for the
                    perfect gift, our knowledgeable team is here to assist you
                    every step of the way. Discover the difference with us and
                    elevate your style to new heights. Finally, we're not just a
                    brand, we're a lifestyle. Join our community of fashion
                    enthusiasts and experience the difference for yourself. With
                    our commitment to style, quality, and customer satisfaction,
                    we're confident that Devanti will become your go-to
                    destination for all your fashion, jewelry, and accessory
                    needs. Embrace your unique style and elevate your wardrobe
                    with us today
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  <!-- Start testimonial section --> */}
        <section class="testimonial__section section--padding">
          <div class="container">
            <div class="section__heading text-center mb-40">
              <h2 class="section__heading--maintitle">Meet Our Team</h2>
            </div>

            {loading ? (
              "Team Loading..."
            ) : (
              <div className="row justify-content-md-center">
                {teamData.length === 0
                  ? "No Testimony Data"
                  : teamData.map((item) => (
                      <div className="col-lg-3" key={item.id}>
                        <div class="testimonial__items text-center">
                          <div class="testimonial__items--thumbnail">
                            <img
                              class="testimonial__items--thumbnail__img border-radius-50"
                              src={`https://api.devanti.ng${item.image}`}
                              alt="testimonial-img"
                            />
                          </div>
                          <div class="testimonial__items--content">
                            <h3
                              class="testimonial__items--title"
                              style={{ color: "black" }}
                            >
                              {item.full_name}
                            </h3>

                            <p
                              style={{
                                color: "#606060",
                                lineHeight: "24px",
                                fontSize: "14px",
                              }}
                            >
                              {item.post_held}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        </section>
        {/* <!-- End testimonial section --> */}
      </main>

      <button id="scroll__top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ionicon"
          viewBox="0 0 512 512"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="48"
            d="M112 244l144-144 144 144M256 120v292"
          />
        </svg>
      </button>
    </>
  );
};

export default About;
