import React from "react";
const TermCondition = () => {
  const content = [
    {
      title: "Use of Website",
      desc: `
      You may use our website for lawful purposes and in accordance with these terms. You agree not to use our website in any way that violates applicable laws or regulations, infringes on the rights of others, or interferes with the operation of the website.
      `,
    },
    {
      title: "Account Registration",
      desc: `Some features of our website may require you to register for an account. When you register, you agree to provide accurate and complete information and to keep your account credentials confidential. You are responsible for all activities that occur under your account, and you agree to notify us immediately of any unauthorized access or use.
      `,
    },
    {
      title: "Product Information",
      desc: `We make every effort to ensure that the information about our products, including descriptions, prices, and availability, is accurate and up to date. However, we do not guarantee the accuracy or completeness of this information and reserve the right to correct any errors, inaccuracies, or omissions at any time without prior notice.
      `,
    },
    {
      title: "Purchases",
      desc: `
      When you make a purchase through our website, you agree to provide accurate and complete billing and shipping information and to pay all charges incurred in connection with your purchase. We reserve the right to refuse or cancel any orders, limit quantities, and discontinue products or services at any time
      `,
    },
    {
      title: "Intellectual Property",
      desc: `All content on our website, including text, images, logos, and graphics, is the property of [Your E-commerce Website] or its licensors and is protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or display any part of our website without our prior written consent.
      `,
    },
    {
      title: "Limitation of Liability",
      desc: `
      In no event shall Medmall or its affiliates, partners, or licensors be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or products. Our total liability to you for any claim arising out of these terms or your use of our website shall not exceed the amount paid by you for the products or services at issue.
      `,
    },
    {
      title: "Governing Law",
      desc: `
      These terms shall be governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of laws principles. Any dispute arising out of or in connection with these terms or your use of our website shall be resolved exclusively in the courts of Nigeria.
      `,
    },
    {
      title: "Changes to Terms",
      desc: `
      We reserve the right to update or modify these terms at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of our website after the posting of revised terms constitutes your acceptance of the changes.
      `,
    },
    {
      title: "Contact Us",
      desc: `If you have any questions or concerns about this Privacy Policy or our data practices, please check our contact page `,
    },
  ];

  return (
    <section className="about__section section--padding mb-95">
      <div className="container">
        <div className="blog__details--wrapper">
          <div className="entry__blog">
            <div
              className="blog__post--header mb-30"
              style={{ marginBottom: "30px" }}
            >
              <h2
                className="post__header--title mb-15"
                style={{ textAlign: "center", color: "var(--secondary-color)" }}
              >
                OUR TERMS AND CONDITIONS
              </h2>
            </div>

            <div className="blog__details--content">
              <p
                className="blog__details--content__subtitle mb-25"
                style={{
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "25px",
                }}
              >
                Welcome to Devanti Couture E-commerce Website! These Terms and
                Conditions govern your access to and use of our website,
                products, and services. By using our website, you agree to
                comply with and be bound by these terms. If you do not agree
                with any part of these terms, please do not use our website.
              </p>

              <ul className="blog__details--content__desc mb-20">
                {content.map((item) => (
                  <li key={item.title}>
                    <h4
                      className="post__header--title mb-10"
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {item.title}
                    </h4>
                    <p
                      className="blog__details--content__subtitle mb-25"
                      style={{
                        marginBottom: "25px",
                        fontSize: "14px",
                        color: "#666",
                      }}
                    >
                      {item.desc}
                    </p>
                  </li>
                ))}
                <li>
                  <b>
                    NB: This Privacy Policy was last updated in March, 2024.
                  </b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermCondition;
