import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import requests from "../lib/requests";
import { Axios } from "../config";

const Hero = () => {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    getBanner();
  }, []);
  const getBanner = async () => {
    try {
      const res = await Axios.get(requests.banner);
      setBanner(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Set to true for automatic sliding
    autoplaySpeed: 2000, // Set the interval (in milliseconds)
  };

  return (
    <div>
      <section className="hero__slider--section color-scheme-2">
        <div className="hero__slider--inner hero__slider--activation swiper">
          <div className="hero__slider--wrapper swiper-wrapper">
            <Slider {...settings}>
              {banner.map((item) => (
                <Link to={item.link} key={item.id}>
                  <div className="swiper-slide ">
                    <div className="hero__slider--items">
                      <div>
                        <img
                          src={`https://api.devanti.ng${item.image}`}
                          alt="banner"
                          style={{ height: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              {/* <Link to="/shop">
                <div className="swiper-slide ">
                  <div className="hero__slider--items home2__slider--bg">
                    <div className="container-fluid">
                      <div className="hero__slider--items__inner hero__slider--bg2__inner"></div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/shop">
                <div className="swiper-slide ">
                  <div className="hero__slider--items home2__slider--bg two">
                    <div className="container-fluid">
                      <div className="hero__slider--items__inner hero__slider--bg2__inner"></div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/shop">
                <div className="swiper-slide">
                  <div className="hero__slider--items home2__slider--bg three">
                    <div className="container-fluid">
                      <div className="hero__slider--items__inner hero__slider--bg2__inner"></div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/shop">
                <div className="swiper-slide ">
                  <div className="hero__slider--items home2__slider--bg four">
                    <div className="container-fluid">
                      <div className="hero__slider--items__inner hero__slider--bg2__inner"></div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/shop">
                <div className="swiper-slide ">
                  <div className="hero__slider--items home2__slider--bg five">
                    <div className="container-fluid">
                      <div className="hero__slider--items__inner hero__slider--bg2__inner"></div>
                    </div>
                  </div>
                </div>
              </Link> */}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
