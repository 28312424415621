const requests = {
  login: "user/auth/login/",
  register: "user/auth/register/",
  contact: "user/auth/contact-us/",
  newsLetter: "user/auth/newsletter/",
  token: "user/token/",
  logout: "user/auth/logout/",
  products: "account/get_products/",
  categories: "account/get_products_categories/",
  mainProducts: "account/get_products_main_categories/",
  subCategories: "/account/get_products_sub_categories/",
  testimony: "account/testimony/",
  shippingmethod: "account/shipping_methods/",
  teams: "/account/our_team/",
  banner:"/account/banners/",

  // SEARCH
  productsearch: "/account/product/search/",
  // PRODUCT ORDER
  productorder: "/account/user_order/",
};
export default requests;
