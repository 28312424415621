import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import style from "../styles/pagination.module.css";

const Pagination = ({ pageCount, handlePageClick }) => {
  return (
    <div style={{ width: "100%", paddingBottom: "12px" }}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <div className="d-flex items-center w-full">
            <span className="d-none d-lg-flex align-items-center">Next</span>
            <BsChevronRight size={20} />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel={
          <div className="d-flex items-center w-full">
            <BsChevronLeft size={20} />
            <span className="d-none d-lg-flex align-items-center">
              Previous
            </span>
          </div>
        }
        renderOnZeroPageCount={undefined}
        containerClassName={style.paginationContainer}
        pageLinkClassName={style.pageLink}
        previousClassName={style.previousLink}
        nextClassName={style.nextLink}
        previousLinkClassName={style.paginationContainer}
        nextLinkClassName={style.paginationContainer}
        activeLinkClassName={style.activeLink}
      />
    </div>
  );
};

export default Pagination;
