import React from "react";

import image from "../assets/img/product/image.png";
import img from "../assets/img/icon/x.png";
import useAuthStore from "../store/store";
import { HiOutlineShoppingCart } from "react-icons/hi";
const OrderCart = () => {
  const { authUser } = useAuthStore();

  return (
    <>
      <div className="account__wrapper">
        <div className="account__content">
          <p className="account__welcome--text">
            Hello, {authUser} welcome to your dashboard!
          </p>
          <div className="" style={{ display: "flex" }}>
            <span className="account__content--title h3 mb-20">
              Orders History
            </span>
          </div>
          <div className="account__table--area">
            <div className="account__table">
              <div className="account__table--body mobile__none">
                <div className="track__order-container">
                  <div className="track__order-content">
                    <p className="content1">
                      {" "}
                      Cup Cake <br />
                      <span className="" style={{ color: "#666666" }}>
                        # 6, 000
                      </span>
                    </p>
                  </div>

                  <div className="track__order-content">
                    <p className="content2">
                      {" "}
                      Order ID # 0000001 <br />
                      <span> Shipped To Oluwatoyin Farinde</span>
                    </p>
                  </div>
                  <div className="track__order-content">
                    <p className="content2">
                      {" "}
                      Order ID # 0000001 <br />
                      <span> Shipped To Oluwatoyin Farinde</span>
                    </p>
                  </div>
                </div>
                <div
                  className="track__order-container second"
                  style={{ gap: "20px" }}
                >
                  <div className="track__order-content">
                    <img src={image} alt="cupcake" />
                  </div>
                  <div
                    className="track__order-content"
                    style={{ width: "300px" }}
                  >
                    <p className="content4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                  <div className="">
                    <p>Buy Again</p>
                    <span>current price</span>
                    <span>$120</span>
                    <select name="" id="">
                      <option value="1">Qty:1</option>
                      <option value="1">Qty:1</option>
                    </select>
                    <hr />
                    <button
                      className="order-btn"
                      style={{ backgroundColor: "blue", color: "white" }}
                    >
                      Buy Again
                    </button>
                  </div>
                </div>
                <div className="track__order-container btn">
                  <button className="order-btn">
                    <HiOutlineShoppingCart color="#02278F" />
                    Buy Again
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCart;
